<template>
  <div>
    <ModuleComponent :module-data="moduleData" @changed="item = $event">
      <template v-slot:customColumn_active="{ rowItem }">
        <TableStatusColumn v-model="rowItem.active"></TableStatusColumn>
      </template>
      <template v-slot:tableFilters="{ filters }">
        <b-container fluid>
          <b-row>
            <b-col cols="3" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.DEFINITIONTYPE')">
                <v-select2
                  v-model="filters.definitionTypeId.value"
                  endPoint="definitionTypes"
                  :placeholder="t('GLOBAL.DEFINITIONTYPE')"
                ></v-select2>
              </b-form-group>
            </b-col>
            <b-col cols="3" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.NAME')">
                <b-form-input
                  name="name"
                  v-model="filters.name.value"
                  :placeholder="t('GLOBAL.NAME')"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="3" sm="3" md="3">
              <b-form-group :label="t('GLOBAL.CODE')">
                <b-form-input
                  name="code"
                  v-model="filters.code.value"
                  :placeholder="t('GLOBAL.CODE')"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </template>
      <template v-slot:form="{ item }">
        <b-row>
          <b-col cols="12" sm="8" md="8">
            <b-form-group :label="t('GLOBAL.NAME')">
              <b-form-input
                name="name"
                v-model="item.name"
                :placeholder="t('GLOBAL.NAME')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="4" md="4">
            <b-form-group :label="t('GLOBAL.CODE')">
              <b-form-input
                name="name"
                v-model="item.code"
                :placeholder="t('GLOBAL.CODE')"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.DESCRIPTION')">
              <b-form-input
                name="name"
                v-model="item.description"
                :placeholder="t('GLOBAL.DESCRIPTION')"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.DEFINITIONTYPE')">
              <v-select2
                v-model="item.definitionTypeId"
                :text="item.definitionType ? item.definitionType.name : ''"
                endPoint="definitionTypes"
                sort="name"
                :placeholder="t('GLOBAL.DEFINITIONTYPE')"
              ></v-select2>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="12" md="12">
            <b-form-group
              :label="t('GLOBAL.PARENT') + ' ' + t('GLOBAL.DEFINITIONTYPE')"
            >
              <v-select2
                v-model="item.parentDefinitionTypeId"
                :text="
                  item.ParentDefinitionType
                    ? item.ParentDefinitionType.name
                    : ''
                "
                endPoint="definitionTypes"
                sort="name"
                :placeholder="
                  t('GLOBAL.PARENT') + ' ' + t('GLOBAL.DEFINITIONTYPE')
                "
              ></v-select2>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.DEFINITIONS.D')">
              <v-select2
                v-model="item.parentDefinitionId"
                :text="item.parentDefinition ? item.parentDefinition.name : ''"
                endPoint="definitions"
                sort="name"
                :filters="{
                  definitionTypeId: {
                    value: item.parentDefinitionTypeId
                  }
                }"
              ></v-select2>
            </b-form-group>
          </b-col>
          <b-col cols="6" sm="6" md="6">
            <b-form-checkbox
              size="lg"
              switch
              v-model="item.active"
              name="active"
              >{{ t("GLOBAL.ACTIVE") }}
            </b-form-checkbox>
          </b-col>
        </b-row>
      </template>
    </ModuleComponent>
  </div>
</template>

<script>
// http://core.datadns.net:5000/swagger/index.html#model-Definition
export default {
  name: "definitions",
  data() {
    return {
      item: {},
      moduleData: {
        name: "definitions",
        primaryKey: "id",
        table: {
          sortBy: "id",
          sortDesc: true,
          graphqlQuery: `
							id,name,code,definitionType{name},active
						`,
          headers: [
            { text: "ID", value: "id", width: "70" },
            {
              text: this.t("GLOBAL.DEFINITIONTYPE"),
              value: "definitionType.name"
            },
            { text: this.t("GLOBAL.NAME"), value: "name" },
            { text: this.t("GLOBAL.CODE"), value: "code" },
            {
              text: this.t("GLOBAL.STATUS"),
              value: "active",
              width: "60",
              align: "center"
            }
          ],
          customColumns: ["active"],
          filters: {
            name: {
              type: "string",
              searchBy: "beginsWith",
              value: null
            },
            code: {
              type: "string",
              searchBy: "beginsWith",
              value: null
            },
            definitionTypeId: {
              type: "number",
              value: null
            }
          }
        },
        form: {
          maxWidth: "80%"
        }
      }
    };
  }
};
</script>
