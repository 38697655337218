var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ModuleComponent',{attrs:{"module-data":_vm.moduleData},on:{"changed":function($event){_vm.item = $event}},scopedSlots:_vm._u([{key:"customColumn_active",fn:function(ref){
var rowItem = ref.rowItem;
return [_c('TableStatusColumn',{model:{value:(rowItem.active),callback:function ($$v) {_vm.$set(rowItem, "active", $$v)},expression:"rowItem.active"}})]}},{key:"tableFilters",fn:function(ref){
var filters = ref.filters;
return [_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"3","sm":"3","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.DEFINITIONTYPE')}},[_c('v-select2',{attrs:{"endPoint":"definitionTypes","placeholder":_vm.t('GLOBAL.DEFINITIONTYPE')},model:{value:(filters.definitionTypeId.value),callback:function ($$v) {_vm.$set(filters.definitionTypeId, "value", $$v)},expression:"filters.definitionTypeId.value"}})],1)],1),_c('b-col',{attrs:{"cols":"3","sm":"3","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.NAME')}},[_c('b-form-input',{attrs:{"name":"name","placeholder":_vm.t('GLOBAL.NAME')},model:{value:(filters.name.value),callback:function ($$v) {_vm.$set(filters.name, "value", $$v)},expression:"filters.name.value"}})],1)],1),_c('b-col',{attrs:{"cols":"3","sm":"3","md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.CODE')}},[_c('b-form-input',{attrs:{"name":"code","placeholder":_vm.t('GLOBAL.CODE')},model:{value:(filters.code.value),callback:function ($$v) {_vm.$set(filters.code, "value", $$v)},expression:"filters.code.value"}})],1)],1)],1)],1)]}},{key:"form",fn:function(ref){
var item = ref.item;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.NAME')}},[_c('b-form-input',{attrs:{"name":"name","placeholder":_vm.t('GLOBAL.NAME')},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.CODE')}},[_c('b-form-input',{attrs:{"name":"name","placeholder":_vm.t('GLOBAL.CODE')},model:{value:(item.code),callback:function ($$v) {_vm.$set(item, "code", $$v)},expression:"item.code"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.DESCRIPTION')}},[_c('b-form-input',{attrs:{"name":"name","placeholder":_vm.t('GLOBAL.DESCRIPTION')},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.DEFINITIONTYPE')}},[_c('v-select2',{attrs:{"text":item.definitionType ? item.definitionType.name : '',"endPoint":"definitionTypes","sort":"name","placeholder":_vm.t('GLOBAL.DEFINITIONTYPE')},model:{value:(item.definitionTypeId),callback:function ($$v) {_vm.$set(item, "definitionTypeId", $$v)},expression:"item.definitionTypeId"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.PARENT') + ' ' + _vm.t('GLOBAL.DEFINITIONTYPE')}},[_c('v-select2',{attrs:{"text":item.ParentDefinitionType
                  ? item.ParentDefinitionType.name
                  : '',"endPoint":"definitionTypes","sort":"name","placeholder":_vm.t('GLOBAL.PARENT') + ' ' + _vm.t('GLOBAL.DEFINITIONTYPE')},model:{value:(item.parentDefinitionTypeId),callback:function ($$v) {_vm.$set(item, "parentDefinitionTypeId", $$v)},expression:"item.parentDefinitionTypeId"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.t('GLOBAL.DEFINITIONS.D')}},[_c('v-select2',{attrs:{"text":item.parentDefinition ? item.parentDefinition.name : '',"endPoint":"definitions","sort":"name","filters":{
                definitionTypeId: {
                  value: item.parentDefinitionTypeId
                }
              }},model:{value:(item.parentDefinitionId),callback:function ($$v) {_vm.$set(item, "parentDefinitionId", $$v)},expression:"item.parentDefinitionId"}})],1)],1),_c('b-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('b-form-checkbox',{attrs:{"size":"lg","switch":"","name":"active"},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}},[_vm._v(_vm._s(_vm.t("GLOBAL.ACTIVE"))+" ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }